<style lang="scss" scoped>
.safeguard_clause {
	padding: 0 0.4rem;
}
::v-deep .cell_title {
	color: $font_color_val;
	font-size: 0.24rem;
}
::v-deep .van-cell {
	padding: 0.2rem 0;
}

.new_main_title {
	display: flex;
	justify-content: flex-start;
	align-items: center;
	font-size: 0.28rem;
	font-weight: 600;
	padding-bottom: 0.2rem;
	margin-top: 0.4rem;
}
.line_title {
	width: 0.08rem;
	height: 0.32rem;
	background: $b_color_main;
	margin-right: 0.2rem;
}
</style>

<template>
	<div class="safeguard_clause">
		<top-nav @back="$router.go(-1)">保障条款</top-nav>
		<!-- <active-title class="title">主条款</active-title> -->
		<div class="new_main_title">
			<div class="line_title"></div>
			<span>主条款</span>
		</div>
		<van-cell v-for="(item, index) in mainClausesList" :key="index" :title="item.docName" @click="cellClick(item)" title-class="cell_title" value-class="cell_value" :border="false" is-link />
		<div class="new_main_title">
			<div class="line_title"></div>
			<span>附加条款</span>
		</div>
		<van-cell v-for="(item, index) in additionClausesList" :key="index" :title="item.docName" @click="cellClick(item)" title-class="cell_title" value-class="cell_value" :border="false" is-link />
	</div>
</template>

<script>
import { Cell } from 'vant';
import { porductClause } from '@/request/api';

export default {
	name: 'safeguardClause',
	components: {
		[Cell.name]: Cell,
	},
	props: {
		productId: {
			type: [Number, String],
			required: true,
		},
		comboId: {
			type: [Number, String],
		},
	},
	created() {
		this.getPorductClause(this.productId);
	},
	data() {
		return {
			mainClausesList: [],
			additionClausesList: [],
			serve: '',
		};
	},
	methods: {
		getPorductClause(productId) {
			porductClause(productId, { comboId: this.comboId }).then(res => {
				this.mainClausesList = res.mainClausesList;
				this.additionClausesList = res.additionClausesList;
				this.serve = res.productFileServer;
			});
		},

		cellClick(info) {
			window.open(this.serve + info.docLink, '_blank');
		},
	},
};
</script>
